.deposit-stack-div {
  margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 25%;
    max-height: 25%;
  }
  
  .deposit-stack-div img {
    margin-bottom: 1rem;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  /* Teléfonos (menos de 600px) */
  @media only screen and (max-width: 600px) {
    .deposit-stack-div {
      max-width: 100%;
      max-height: 100%;
    }
  }
  
  /* Tabletas (601px a 992px) */
  @media only screen and (min-width: 601px) and (max-width: 992px) {
    .deposit-stack-div {
      max-width: 50%;
      max-height: 50%;
    }
  }
  
  /* Pantallas grandes (993px y más) */
  @media only screen and (min-width: 993px) {
    .deposit-stack-div {
      max-width: 50%;
      max-height: 50%;
    }
  }
  
  /* Pantallas extra grandes (1200px y más) */
  @media only screen and (min-width: 1200px) {
    .deposit-stack-div {
      max-width: 50%;
      max-height: 50%;
    }
  }