.cart-container {
	display: flex;
	flex-direction: column;
	max-width: 424px;
	max-height: 467px;
	height: 100%;
	flex: 1;
	padding-top: 24px;
	padding-bottom: 24px;
	border: 1px solid #27314b;
	border-radius: 30px;
	bottom: -80px;
	background: radial-gradient(66.74% 66.74% at 50% 33.26%, rgba(231, 216, 53, 0.349) 0%, rgba(0, 0, 0, 0) 100%), #080f22;
	margin: auto;
	align-items: center;
}

.cart-container-image {
	object-fit: contain;
	margin: 0 auto;
	width: 100%;
	height: 9rem;
	overflow-clip-margin: content-box;
	overflow: clip;
	border-radius: 30px;
}

.cart-container-button {
	border: solid 1px white;
	width: fit-content;
	padding: 0.5rem 1.5rem;
	border-radius: 8px;
	text-decoration: none;
	font-weight: bold;
	color: #000;
	background-color: white;
}

.cart-container-button:hover {
	color: #000;
	border-color: rgba(231, 216, 53, 0.349);
	background-color: #425789;
	transition: all 0.3s ease-in-out;
}

.cart-container:hover {
	background: radial-gradient(66.74% 66.74% at 50% 33.26%, rgba(231, 216, 53, 0.5) 0%, rgba(0, 0, 0, 0) 100%), #080f22;
}
.stack-div-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	max-height: 100%;
	padding: 2rem;
}

.stack-div-card img {
	margin-bottom: 1rem;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

/* Teléfonos (menos de 600px) */
@media only screen and (max-width: 600px) {
	.stack-div-card {
		max-width: 100%;
		max-height: 100%;
	}
}

/* Tabletas (601px a 992px) */
@media only screen and (min-width: 601px) and (max-width: 992px) {
	.stack-div-card {
		max-width: 100%;
		max-height: 100%;
	}
}

/* Pantallas grandes (993px y más) */
@media only screen and (min-width: 993px) {
	.stack-div-card {
		max-width: 100%;
		max-height: 100%;
	}
}

/* Pantallas extra grandes (1200px y más) */
@media only screen and (min-width: 1200px) {
	.stack-div-card {
		max-width: 100%;
		max-height: 100%;
	}
}
