:root {
	/* Toolbar and buttons */
	--tv-toolbar-button-background: #3a3a3a;
	--tv-toolbar-button-hover-background: #4a4a4a;
	--tv-toolbar-icon-color: #1e90ff; /* Change to your desired color */
}

/* Custom toolbar icon styles */
.tv-toolbar .icon {
	fill: var(--tv-toolbar-icon-color);
}

/* Adjust other components as needed */
.tv-main-widget {
	background-color: #2e2e2e;
	color: #eaeaea;
}
