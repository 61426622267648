/* Estilo personalizado para w-lg-50 */
@media (min-width: 992px) {
  .w-lg-50 {
    width: 50% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-30 {
    width: 30% !important;
  }
}